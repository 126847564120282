// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-sparkyvft-src-pages-404-tsx": () => import("./../../../node_modules/sparkyvft/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-sparkyvft-src-pages-404-tsx" */),
  "component---node-modules-sparkyvft-src-pages-index-tsx": () => import("./../../../node_modules/sparkyvft/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-sparkyvft-src-pages-index-tsx" */),
  "component---node-modules-sparkyvft-theme-templates-card-page-js": () => import("./../../../node_modules/sparkyvft/theme/templates/cardPage.js" /* webpackChunkName: "component---node-modules-sparkyvft-theme-templates-card-page-js" */),
  "component---node-modules-sparkyvft-theme-templates-page-js": () => import("./../../../node_modules/sparkyvft/theme/templates/page.js" /* webpackChunkName: "component---node-modules-sparkyvft-theme-templates-page-js" */)
}

